interface ICampaignNumber {
  id: number;
  groupname: string;
  number: string;
  hour_limit: string;
  createdAt: string;
}

const campaignNumbers: Array<ICampaignNumber> = [
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
  {
    id: Math.floor(Math.random() * 99999) + 1,
    groupname: "Xsquare Group",
    number: "80",
    hour_limit: "Completed",
    createdAt: "14 Dec 2020, 8:43 pm",
  },
];

export { ICampaignNumber };

export default campaignNumbers;
