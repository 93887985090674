
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
// import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
// import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import campaigntemplate from "@/core/data/campaigntemplate";
import campaignNumber from "@/core/data/campaignNumber";
import { ICampaignNumber } from "@/core/data/campaignNumber";
import { ICampaignTemplate } from "@/core/data/campaigntemplate";
import { ICampaignMessage } from "@/core/data/campaignMessage";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute, useRouter } from "vue-router";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    // ExportCustomerModal,
    // AddCustomerModal,
    VPagination,
  },
  setup() {
    var formData = ref<any>(null);
    const store = useStore();
    const limit = ref(10);
    const checkedCampaigns = ref([]);
    const showTable = ref(false);
    const showTableTemplate = ref(false);
    const showTableMessage = ref(false);
    const route = useRoute();
    const total_pages = ref(1);
    const idOfcampaign = ref<any>("");
    const tableHeader = ref([
      {
        name: "Name",
        key: "groupname",
        sortable: true,
      },
      {
        name: "Number",
        key: "number",
        sortable: true,
      },
      {
        name: "Send Limit",
        key: "hour_limit",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "createdAt",
        sortable: true,
      },
    ]);

    const tableData = ref<Array<ICampaignNumber>>(campaignNumber);
    const initCustomers = ref<Array<ICampaignNumber>>([]);

    const tableHeaderOne = ref([
      {
        name: "Name",
        key: "title",
        sortable: true,
      },
      {
        name: "Message",
        key: "template_content",
        sortable: true,
      },
      {
        name: "Date & Time",
        key: "createdAt",
        sortable: true,
      },
    ]);
    const tableDataOne = ref<Array<ICampaignTemplate>>([]);
    const initCustomersOne = ref<Array<ICampaignTemplate>>([]);

    const tableHeaderTwo = ref([
      {
        name: "From",
        key: "twilio_number",
        sortable: true,
      },
      {
        name: "To",
        key: "number",
        sortable: true,
      },
      {
        name: "Message",
        key: "message",
        sortable: true,
      },
      {
        name: "Cost of Text",
        key: "price",
        sortable: true,
      },
      {
        name: "Status",
        key: "send_status",
        sortable: true,
      },
      {
        name: "Date & Time",
        key: "createdAt",
        sortable: true,
      },
    ]);
    const tableDataTwo = ref<Array<ICampaignMessage>>([]);
    const initCustomersTwo = ref<Array<ICampaignMessage>>([]);

    onMounted(() => {
      setCurrentPageBreadcrumbs("View Campaign", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
      getCampaign();
      getCampaignNumber();
      getCampaignTemplate();
      getCampaignMessage(1);
    });
    const totalMessage = ref(0);
    const sendSms = ref(0);
    const deliveredSms = ref(0);
    const responseSms = ref(0);
    const undeliveredSms = ref(0);
    const getCampaignNumber = () => {
      var id = route.params.id;
      var request = {
        url: `campaign-number/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          console.log("campaign number", data);
          tableData.value = data.data.campaign;
          totalMessage.value = data.data.total;
          sendSms.value = data.data.send;
          deliveredSms.value = data.data.delivered;
          responseSms.value = data.data.response;
          undeliveredSms.value = data.data.undelivered;

          initCustomers.value.splice(
            0,
            tableData.value.length,
            ...tableData.value
          );
          //total_pages.value = Math.ceil(data.total/limit.value);
          showTable.value = true;
          // console.log('datata',tableData.value);
        }
      });
    };

    const getCampaignTemplate = () => {
      var id = route.params.id;
      var request = {
        url: `campaign-template/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // console.log('data',data);
          tableDataOne.value = data.data;
          initCustomersOne.value.splice(
            0,
            tableDataOne.value.length,
            ...tableDataOne.value
          );
          showTableTemplate.value = true;
          // console.log('datata',tableData.value);
        }
      });
    };

    const getCampaignMessage = (page) => {
      var id = route.params.id;
      var request = {
        url: `campaign-message/${id}?skip=${page}&limit=${limit.value}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // console.log('data',data);
          tableDataTwo.value = data.data;
          initCustomersTwo.value.splice(
            0,
            tableDataOne.value.length,
            ...tableDataTwo.value
          );
          total_pages.value = Math.ceil(data.total / limit.value);
          showTableMessage.value = true;
          // console.log('datata',tableData.value);
        }
      });
    };

    const deleteMessage = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // if (result.isConfirmed) {
          var request = {
            url: `message-destroy/${id}`,
          };
          store.dispatch(Actions.DELETE, request).then((data) => {
            if (data) {
              showTableMessage.value = false;
              Swal.fire(
                "Deleted!",
                "Campaign message has been deleted.",
                "success"
              );
              getCampaignMessage(1);
            }
          });
          // }
        }
      });
    };

    const campaignStart = () => {
      var id = route.params.id;
      var request = {
        url: `campaign-Start/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data.data) {
           Swal.fire(
            "Deleted!",
            "Campaign Start Successfully!",
            "success"
          );
          // Swal.fire({
          //   text: "Campaign Start Successfully!",
          //   icon: "success",
          //   showCancelButton: true,
          //   confirmButtonColor: "#3085d6",
          //   cancelButtonColor: "#d33",
          //   confirmButtonText: "Yes",
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     location.reload();
          //   }
          // });
        }
      });
    };

    const campaignPause = () => {
      var id = route.params.id;
      var request = {
        url: `campaign-Pause/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data.data) {
          Swal.fire(
                "Deleted!",
                "Campaign Pause Successfully",
                "success"
              );
          // Swal.fire({
          //   text: "Campaign Pause Successfully!",
          //   icon: "success",
          //   showCancelButton: true,
          //   confirmButtonColor: "#3085d6",
          //   cancelButtonColor: "#d33",
          //   confirmButtonText: "Yes",
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     location.reload();
          //   }
          // });
        }
      });
    };

    const getCampaign = () => {
      var id = route.params.id;
      var request = {
        url: `campaign-view/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          console.log("campaign view", data);

          formData.value = data.data;
          // formData.value = {
          //   groupname: data.data.group_id.groupname,
          //   schedule_type: data.data.schedule_type,
          //   message_type: data.data.message_type,
          //   schedule_date_time: data.data.schedule_date_time,
          //   createdAt: data.data.createdAt,
          //   status: data.data.status,
          //   totalmsg: data.data.totalmsg,
          //   id: data.data._id,
          //   number_limit: data.data.number_limit

          // };
        }
      });
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<ICampaignNumber> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const dateTime = (value) => {
      var value2 = moment(value).format("YYYY-MM-DD HH:mm:ss");
      return value2;
    };

    const updateHandler = (value) => {
      showTableMessage.value = false;
      getCampaignMessage(value);
    };
    const page = ref(1);
    return {
      tableData,
      tableHeader,
      // deleteFewCampaign,
      search,
      searchItems,
      checkedCampaigns,
      // deleteCampaign,
      showTable,
      getCampaign,
      dateTime,
      formData,
      getCampaignNumber,
      idOfcampaign,
      campaignStart,
      campaignPause,
      tableHeaderOne,
      tableDataOne,
      initCustomersOne,
      getCampaignTemplate,
      showTableTemplate,
      tableHeaderTwo,
      tableDataTwo,
      initCustomersTwo,
      showTableMessage,
      deleteMessage,
      total_pages,
      updateHandler,
      totalMessage,
      sendSms,
      deliveredSms,
      responseSms,
      undeliveredSms,
      page
    };
  },
});
